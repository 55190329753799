import React, { useState } from 'react';
import Header from './Header';
import Banner from './Banner';
import Footer from './Footer';
import '../App.css';
import '../GlobalStyles.css'; // Shared styles
import '../HowTo.css'; // Use the existing accordion CSS

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is PredictXY?",
      answer:
        "PredictXY is an advanced forecasting tool designed to help businesses and individuals predict future trends with precision and ease. Utilizing state-of-the-art machine learning algorithms and statistical models, PredictXY processes your data to uncover patterns, trends, and insights that may not be immediately visible.\n\nWhether you need to forecast sales, manage inventory, predict demand, or optimize operations, PredictXY empowers you to make data-driven decisions with confidence.\n\nBuilt for simplicity and efficiency, PredictXY eliminates the need for technical expertise, allowing users of all backgrounds to upload their data and generate highly accurate forecasts in just a few clicks. The platform's intuitive interface ensures that complex predictive analytics are accessible and actionable for everyone, while its robust infrastructure guarantees security and reliability.\n\nBy combining machine learning, advanced analytics, and a deep understanding of data dynamics, PredictXY enables you to stay ahead of the competition and make smarter, forward-looking decisions.",
    },
    {
      question: "How does PredictXY work?",
      answer:
        "Our platform uses advanced predictive analytics, machine learning, and statistical models to forecast trends based on your uploaded data.\n\nOnce you upload your data, the system analyzes patterns, processes the information, and delivers predictions in an easy-to-understand format. The output includes actionable insights that help you make informed decisions and strategize effectively.",
    },
    {
      question: "Who can benefit from a forecasting platform?",
      answer:
        "PredictXY is designed for businesses, analysts, researchers, and anyone who wants to use data to gain actionable insights and make better decisions.\n\nFrom small businesses looking to optimize inventory to large enterprises needing sales projections, PredictXY provides the tools to streamline processes and plan for the future with greater accuracy.",
    },
    {
      question: "What kind of data do I need to use the platform?",
      answer:
        "Currently, the platform processes monthly data for forecasting.\n\nIn the coming weeks, we will introduce support for daily and weekly-level data. If you have daily or weekly data, you can transform it into monthly aggregates and upload it to the platform in the meantime.\n\nThe more structured and clean your data, the better and more accurate the predictions will be.",
    },
    {
      question: "How accurate are the forecasts provided by PredictXY?",
      answer:
        "PredictXY uses state-of-the-art predictive algorithms and machine learning models to ensure high levels of accuracy.\n\nHowever, the accuracy of predictions depends on the quality, completeness, and relevance of the data you provide. We recommend uploading clean and consistent data for the best results.",
    },
    {
      question: "Do I need technical expertise to use PredictXY?",
      answer:
        "No, PredictXY is designed to be user-friendly and intuitive. You don’t need technical expertise to upload data or interpret forecasts.\n\nThe platform is built with a clean interface that guides you through every step, ensuring that anyone—regardless of technical background—can easily use it. Advanced users can still access detailed analytics and leverage deeper insights.",
    },
    {
      question: "How do I get started with PredictXY?",
      answer:
        "Getting started is easy! Sign up on our platform, upload your data, select the parameters for forecasting, and view the results in just a few clicks.\n\nOur guided onboarding process ensures you can start generating predictions quickly. Additionally, our support team is available to help you with any questions or issues as you begin using PredictXY.",
    },
    {
      question: "Why is forecasting important for businesses?",
      answer:
        "Forecasting is essential for businesses because it helps anticipate future trends, enabling proactive decision-making. Accurate forecasting allows businesses to optimize resource allocation, reduce costs, and plan for future growth effectively.\n\nBy understanding demand patterns, businesses can prevent overproduction or understocking, ensuring better inventory management. Forecasting also helps with budgeting, staffing, and identifying potential risks, which are critical for maintaining operational efficiency and achieving long-term success.",
    },
  ];

  return (
    <div className="frcg-wrap">
      <Header />
      <Banner />
      <div className="faq-content">
        <div className="common-banner">
          <div className="tilted-background">
            <div className="tilted-background-content">
              <h1>
                YOUR QUESTIONS, <span className="highlight">ANSWERED</span>.
              </h1>
            </div>
          </div>
        </div>
        <div className="common-content">
          <div className="accordion">
            {faqData.map((faq, index) => (
              <div key={index} className="accordion-item">
                <div
                  className={`accordion-header ${
                    activeIndex === index ? 'active' : ''
                  }`}
                  onClick={() => toggleAccordion(index)}
                >
                  {faq.question}
                  <span className="accordion-icon">
                    {activeIndex === index ? '-' : '+'}
                  </span>
                </div>
                {activeIndex === index && (
                  <div className="accordion-content faq-content-padding">
                    <p>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;
