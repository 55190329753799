import React from 'react';
import Header from './Header';
import Banner from './Banner';
import Footer from './Footer';
import '../App.css';
import '../GlobalStyles.css'; // Use shared styles

const AboutUs = () => {
  return (
    <div className="frcg-wrap">
      <Header />
      <Banner />
      <div className="about-us-content">
        <div className="common-banner">
          <div className="tilted-background">
            <div className="tilted-background-content">
              <h1>
                THE EXPERTS IN <span className="highlight">DATA-DRIVEN</span> FORECASTING.
              </h1>
            </div>
          </div>
        </div>
        <div className="common-content">
          <p>
            Welcome to PredictXY, your trusted partner in data-driven decision-making. Our
            platform is designed to help businesses and individuals forecast future trends with
            accuracy and ease.
          </p>
          <p>
            Our mission is to empower our users with predictive insights that lead to smarter
            business strategies and improved outcomes. At PredictXY, we believe in the power of
            data, and we are committed to providing cutting-edge tools that make complex data
            analysis accessible to everyone.
          </p>
          <p>
            Whether you're looking to optimize your sales forecasts, manage your inventory, or
            simply understand your market better, PredictXY has the tools and expertise to help
            you succeed.
          </p>
          <p>
            With a team of data scientists, engineers, and industry experts, we combine technical
            excellence with a deep understanding of market dynamics. We believe that data is one
            of the most powerful assets a business can have, and our goal is to unlock its full
            potential for you.
          </p>
          <p>Join us on the journey to predictive success. Your future is in the data.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
