import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import '../App.css';
import '../Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top colorful-background">
        <div className="container">
          <ul className="footer-links">
            <li><Link to="/about-us">ABOUT US</Link></li> {/* Update to use Link */}
            {/* <li><a href="#!" onClick={() => alert("Coming soon!")}>CONTACT US</a></li> */}
            <li><Link to="/faq">FAQ</Link></li> {/* Update to use Link */}
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <span className="text-muted">Copyright © 2024 PredictXY. All rights reserved.</span>
          <div className="footer-links">
            {/* <a href="#!" onClick={() => alert("Coming soon!")}>TERMS & CONDITIONS</a> | 
            <a href="#!" onClick={() => alert("Coming soon!")}>PRIVACY POLICY</a> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
