import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Added for routing
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import MainContent from './MainContent';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Header from './Header';
import OverlayPage from './OverlayPage';
import WIP from './Wip';
import AboutUs from './AboutUs'; // Importing AboutUs component
import FAQ from './FAQ'; // Importing FAQ component
import '../Footer.css';
import '../Header.css';
import '../Sidebar.css';
import '../DataVisualization.css';
import Banner from './Banner'; // Ensure you're using the same Banner
import moment from 'moment';
import Spinner from './Spinner'; // Importing a Spinner component
import { Link } from 'react-router-dom'; // Import Link

function App() {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [versionId, setVersionId] = useState(null);
  const [showChart, setShowChart] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false); // Default to false
  const [isWIPVisible, setIsWIPVisible] = useState(false); // Default to false
  const [selectedDependent, setSelectedDependent] = useState([]);
  const [selectedIndependent, setSelectedIndependent] = useState([]);
  const [selectedDateColumn, setSelectedDateColumn] = useState([]); // New state for period column
  const [loading, setLoading] = useState(false); // State for loading spinner

  // Use useRef for file input
  const fileInputRef = useRef(null);

  const resetSession = () => {
    setFile(null);
    setData([]);
    setHeaders([]);
    setVersionId(null);
    setSelectedDependent([]);
    setSelectedIndependent([]);
    setSelectedDateColumn([]); // Reset period column
    setShowChart(false);
    setShowFields(false);
  };

  const fetchData = async () => {
    if (!versionId) {
      console.error('No versionId found. Cannot fetch data.');
      return;
    }
    setLoading(true); // Start the spinner
    try {
      const response = await axios.get(`https://predictxy.com:8443/api/load`, { params: { versionId } });
      setData(response.data.data);
      setHeaders(response.data.headers);
      console.log('Fetched data:', response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Stop the spinner
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      console.error('No file selected.');
      return;
    }
    resetSession(); // Clear previous session

    setFile(selectedFile);
    console.log('File selected:', selectedFile);

    const formData = new FormData();
    formData.append('file', selectedFile);

    setLoading(true); // Start the spinner
    try {
        const uploadResponse = await axios.post(`https://predictxy.com:8443/api/process`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Upload response:', uploadResponse.data);

      setVersionId(uploadResponse.data.versionId);
      setHeaders(uploadResponse.data.headers);
      setData(uploadResponse.data.data);
      setShowFields(false);
      setShowChart(false);
    } catch (error) {
      console.error('Error uploading or fetching data:', error);
    } finally {
      setLoading(false); // Stop the spinner
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleShowChart = async () => {
    if (!versionId) {
      console.error('No versionId found. Cannot show chart.');
      return;
    }
    setLoading(true); // Start the spinner
    try {
      const response = await axios.get(`https://predictxy.com:8443/api/load-chart`, { params: { versionId } });
      setData(response.data);
      setShowChart(true);
      setShowFields(false);
      console.log('Chart data loaded:', response.data);
    } catch (error) {
      console.error('Error loading chart data:', error);
    } finally {
      setLoading(false); // Stop the spinner
    }
  };

  const handleProcessData = async () => {
    if (!versionId) {
      console.error('No versionId found. Cannot process data.');
      return;
    }
    setLoading(true); // Start the spinner
    try {
      const dependentVar = selectedDependent.length > 0 ? selectedDependent[0].value : null;
      const independentVars = selectedIndependent.map((item) => item.value);
      const periodVar = selectedDateColumn.length > 0 ? selectedDateColumn[0].value : null; // Get the selected period variable

      const response = await axios.post(`https://predictxy.com:8443/api/process-data`, {
        versionId,
        dependentVar,
        independentVars,
        periodVar, // Pass the selected period variable to the backend
      });

      setData(response.data);
      setShowChart(false);
      console.log('Processed data:', response.data);

      const headersWithFORECAST = Object.keys(response.data[0]);
      setHeaders(headersWithFORECAST);
      setShowFields(false);
    } catch (error) {
      console.error('Error processing data:', error);
    } finally {
      setLoading(false); // Stop the spinner
    }
  };

  const handlePredict = async () => {
    if (!versionId) {
      console.error('No versionId found. Cannot predict data.');
      return;
    }
    setLoading(true); // Start the spinner
    try {
      const dependentVar = selectedDependent.length > 0 ? selectedDependent[0].value : null;
      const independentVars = selectedIndependent.map((item) => item.value);
      const periodVar = selectedDateColumn.length > 0 ? selectedDateColumn[0].value : null; // Get the selected period variable

      const response = await axios.post(`https://predictxy.com:8443/api/process-data`, {
        versionId,
        dependentVar,
        independentVars,
        periodVar, // Pass the selected period variable to the backend
      });

      setData(response.data);
      setShowChart(true);
      console.log('Predicted data:', response.data);

      const headersWithFORECAST = Object.keys(response.data[0]);
      setHeaders(headersWithFORECAST);
      setShowFields(false);
    } catch (error) {
      console.error('Error predicting data:', error);
    } finally {
      setLoading(false); // Stop the spinner
    }
  };

  const handleExport = async () => {
    if (!versionId) {
      console.error('No versionId found. Cannot export data.');
      return;
    }
    setLoading(true); // Start the spinner
    try {
        const response = await axios.get(`https://predictxy.com:8443/api/export-data`, {
        params: { versionId },
        responseType: 'blob', // Important to handle file downloads
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const timestamp = moment().format('YYYYMMDD_HHmmss');
      link.setAttribute('download', `export_${timestamp}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting data:', error);
    } finally {
      setLoading(false); // Stop the spinner
    }
  };

  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

  const handleShowFields = async () => {
    await fetchData(); // Ensure fetchData completes
    setShowFields(true);
    setShowChart(false);
};

  const handleShowWIP = () => {
    setIsWIPVisible(true);
  };

  const closeWIP = () => {
    setIsWIPVisible(false);
  };

  useEffect(() => {
    setIsOverlayVisible(true);
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="frcg-wrap">
              {loading && <Spinner />}
              <Header setShowWip={handleShowWIP} />
              <Banner />
              <main role="main">
                <Sidebar
                  fileInputRef={fileInputRef}
                  handleFileChange={handleFileChange}
                  triggerFileInput={triggerFileInput}
                  handleShowFields={handleShowFields}
                  handleShowChart={handleShowChart}
                  handleProcessData={handleProcessData}
                  handleExport={handleExport}
                  data={data}
                  headers={headers}
                  showChart={showChart}
                  showFields={showFields}
                  selectedDependent={selectedDependent}
                  setSelectedDependent={setSelectedDependent}
                  selectedIndependent={selectedIndependent}
                  setSelectedIndependent={setSelectedIndependent}
                  selectedDateColumn={selectedDateColumn}
                  setSelectedDateColumn={setSelectedDateColumn}
                  setLoading={setLoading}
                />
                <MainContent
                  data={data}
                  headers={headers}
                  showChart={showChart}
                  showFields={showFields}
                  selectedDependent={selectedDependent}
                  setSelectedDependent={setSelectedDependent}
                  selectedIndependent={selectedIndependent}
                  setSelectedIndependent={setSelectedIndependent}
                  selectedDateColumn={selectedDateColumn}
                  setSelectedDateColumn={setSelectedDateColumn}
                />
              </main>
              <Footer onShowWIP={handleShowWIP} />
              <OverlayPage isVisible={isOverlayVisible} onClose={toggleOverlay} />
              <WIP isVisible={isWIPVisible} onClose={closeWIP} />
            </div>
          }
        />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/faq" element={<FAQ />} />
      </Routes>
    </Router>
  );
}

export default App;
