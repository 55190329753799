import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import hooks for navigation
import '../App.css';
import '../Header.css';

function Header({ setShowWip }) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleScrollToSection = (event, sectionId) => {
    event.preventDefault();

    if (location.pathname !== '/') {
      // If not on the home page, navigate to home and scroll after rendering
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Wait for navigation to complete before scrolling
    } else {
      // If already on the home page, just scroll
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <div className="header-bar">
      <div className="button-container">
        <button
          className="header-button"
          onClick={(e) => handleScrollToSection(e, 'how-to')}
        >
          START FORECASTING IN SIMPLE STEPS
        </button>
      </div>
    </div>
  );
}

export default Header;
