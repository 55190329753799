import React from 'react';
import { Link } from 'react-router-dom';

function Banner() {
  return (
    <div className="Banner" role="banner">
      {/* PredictXY text included in the Banner */}
      <h1 className="frcg-titlebar">
        <Link to="/" style={{ textDecoration: 'none' }}>
          Predict<span style={{ color: 'orangered' }}>X</span>
          <span style={{ color: 'green' }}>Y</span>
        </Link>
      </h1>
      <picture>
        <img src="/frcg_banner.JPG" className="img-fluid" alt="Manila cityscape" />
      </picture>
    </div>
  );
}

export default Banner;
